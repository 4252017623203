@font-face {
  font-family: LevelOne;
  src: url('./assets/fonts/LevelOne.woff');
}

body {
  font-family: LevelOne;
  background-color: black;
  max-width: 1280px;
  margin: 0 auto;
}

.overlay {
  opacity: 0.8;
}

.box {
  min-height: 300px;
  text-align: center;
  border: 5px solid black;
}

.textbox {
  min-height: 300px;
  text-align: center;
  border: 5px solid black;
  padding: 40px;
}

.topicsheader {
  padding-top: 20px;
}

.topicsbox {
  padding: 10px;
  text-align: center;
  margin-top: 2px;
}

.topicsbox h1 {
  font-size: 1.5rem;
}

.topicsbox h2 {
  font-size: 1rem;
}

.topicsbox p {
  font-size: 0.8rem;
}

.speakerheader {
  padding-top: 20px;
}

.speakerbox {
  text-align: center;
  border: 5px solid black;
}

.imgsmall {
  width: 150px;
}

.speakerbox img {
  width: 100%;
  margin-bottom: 10px;
}

.agendabox {
  padding-top: 10px;
  text-align: center;
  border: 5px solid black;
}

.speaker-bio {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
  margin-right: 10px;
  display: none;
  font-size: 0.8rem;
}

.speakerbox:hover .speaker-bio {
  display: block;
}
/*
.speakerbox:hover img {
  opacity: 0.1;
}
*/
.countdown {
  padding-top: 20px;
}

.navbox {
  min-height: 300px;
  text-align: center;
  border: 5px solid black;
  background-color: white;
}

.navbox:hover {
  background-color: #1E81A0;
  transition: background-color 1s;
  cursor: pointer;
}

.video-navbox {
  min-height: 300px;
  text-align: center;
  border: 5px solid black;
  opacity: 1;
}

.video-navbox:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 1s;
  border: 1px solid #1E81A0;
}

.box h1 {
  margin-top: 90px;
}

.box h2 {
  margin-top: 90px;
}

.navbox h1 {
  margin-top: 90px;
}

.video-navbox h5 {
  margin-top: 80%;
  background: black;
  border-radius: 8px;
  padding: 5px;
}

.lead h2 {
  margin-top: 20px;
  text-align: center;
}

a {
  color: white;
}

a.navbox {
  color: black;
}

a:visited {
  color: white;
}

a.navbox:visited {
  color: black;
}

a:hover {
  color: white;
}

a.navbox:hover {
  color: black;
  text-decoration: none;
}

header {
  width: 100%;
}

.footer {
  font-size: 0.8em;
  font-style: italic;
}

.newsletter {
  width: 60%;
  margin: 0 auto;
}

.carousel-item {
  border: 2px solid black;
}

.carousel-item-nohover {
  border: 2px solid black;
}

.carousel-item:hover {
  border: 2px solid #1E81A0;
  cursor: pointer;
}

.inner {
  position: relative;
  width: 80%;
  margin: 0 auto;
}

#stswe-video-player {
  position: absolute;
  top: 90px;
  z-index: 20;
  border: 5px solid white;
}

#stswe-dimmer {
  background: black;
  opacity: 0.8;
  z-index: 10;
  position: absolute;
  top: 78px;
  width: 100%;
}

#frontpagevideo {
  position: fixed;
  max-width: 50vw;
  height: 50vw;
  top: 50px;
  right: 30px;
  background: black;
  border: solid 3px white;
  z-index: 40;
  transition: visibility 0s linear 0.7s, opacity 0.7s ease-in-out;
}

.hidden {
  opacity: 0; visibility:hidden;
}

.visible {
  transition-delay: 0s; visibility: visible; opacity: 1;
}

.emphasize {
  color: #1E81A0
}

.closebutton {
  position: relative;
  border-radius: 4px;
  background-color: white;
  color: black;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
  overflow: auto;
  z-index: 50;
  float: right;
}

.reservation a {
  color: white;
}

li {
  text-align: left;
}
